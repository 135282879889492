import styled from 'styled-components';

export const ClearLink = styled.a`
  background: transparent;
  color: inherit;
  border-radius: ${props => props.theme.buttonRadius};
  text-align: inherit;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;

  @media (any-hover: hover) {
    &:hover {
      background-color: ${props => props.theme.hoverColor};
    }
  }

  &:active {
    background-color: transparent;
    outline: 0;
  }
`;

export const InverseClearLink = styled(ClearLink)`
  color: ${props => props.theme.inverseFontColor1};

  @media (any-hover: hover) {
    &:hover {
      background-color: ${props => props.theme.hoverColorInverted};
    }
  }
`;
