import { STORAGE_PREFIX } from '#/shared/constants';

export function storeItem(
  key: string,
  object: unknown,
  errorCallback: (() => void) | null = null
): void {
  try {
    localStorage.setItem(`${STORAGE_PREFIX}.${key}`, JSON.stringify(object));
  } catch (e) {
    console.warn(
      'The trimet.org app can not save user preferences at this time. Local Storage may be disabled for this site. Please re-enable this feature for the full user experience.'
    );
    if (errorCallback) errorCallback();
  }
}

export function getItem<T>(key: string, notFoundValue: T = null as T): T {
  let retVal = notFoundValue;
  try {
    const itemAsString = localStorage.getItem(`${STORAGE_PREFIX}.${key}`);
    if (itemAsString === 'false') {
      retVal = false as T;
    } else if (itemAsString === 'true') {
      retVal = true as T;
    } else {
      const json = JSON.parse(itemAsString || 'null');
      if (json !== null) {
        return json as T;
      }
    }
  } catch (e) {
    retVal = notFoundValue;
  }
  return retVal;
}

export function removeItem(key: string): void {
  localStorage.removeItem(`${STORAGE_PREFIX}.${key}`);
}
