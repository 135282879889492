import styled from 'styled-components';

export const ClearButton = styled.button`
  background: transparent;
  color: inherit;
  border: 0;
  text-align: inherit;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;

  /* on mobile the hover state is retained after a button is clicked and isn't
  cleared until another element is clicked so hover styling like this only works
  works properly on desktop: https://stackoverflow.com/a/57419798/2167004 */
  @media (any-hover: hover) {
    &:hover {
      background-color: ${props => props.theme.hoverColor};
    }
  }

  /* We are relying on focus-visible to display tabbed focus states on 
  elements: https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press
  */
  &:focus {
    background-color: transparent;
  }

  &:active {
    background-color: transparent;
    outline: 0;
  }
`;

export const InverseClearButton = styled(ClearButton)`
  color: ${props => props.theme.inverseFontColor1};

  @media (any-hover: hover) {
    &:hover {
      background-color: ${props => props.theme.hoverColorInverted};
    }
  }
`;
