// TODO: Remove this exception once the theme migration is complete
/* eslint-disable @typescript-eslint/no-unused-vars */

/**
 * The pattern to follow is pick from the palette and apply to a constant
 * variable that describes the element or component you are styling.
 * If style applies to both themes, it should be placed in BASE_THEME.
 */

/**
 * TriMet Color Palette
 */

const white = '#ffffff';
const lightestGray = '#f5f5f5';
const lighterGray = '#e9e9e9';
const lightGray = '#ccc';
const gray = '#a6a6a6';
const darkGray = '#808080';
const darkerGray = '#515151';
const darkestGray = '#2b2b2b';
const charcoal = '#222222';
const black = '#000000';

const lightestBlue = '#E6ECF4';
const lighterBlue = '#B5C8DE';
const lightBlue = '#86A4C7';
const blue = '#084c8d'; // TriMet Blue
const darkBlue = '#003d78';
const darkerBlue = '#00386E';
const darkestBlue = '#002b54';

const lightestDeepBlue = '#4170a4';
const lighterDeepBlue = '#335780';
const lightDeepBlue = '#1f354e';
const deepBlue = '#152435'; // TriMet Deep Blue
const darkDeepBlue = '#111e2d';
const darkerDeepBlue = '#08121d';
const darkestDeepBlue = '#03070e';

const lightestGreen = '#CEEEC3';
const lighterGreen = '#B0DCA1';
const lightGreen = '#82C26B';
const green = '#61A744'; // TriMet Green
const darkGreen = '#3A840B';
const darkerGreen = '#2D6E00';
const darkestGreen = '#305E13';

const lightestRed = '#eaa296';
const lighterRed = '#dd7c6e';
const lightRed = '#ce5345';
const red = '#c63c2f'; // TriMet Red, Alert red,...
const darkRed = '#ac3327';
const darkerRed = '#922a20';
const darkestRed = '#7a2119';

const lightestYellow = '#fcf6d2';
const lighterYellow = '#faf2ba';
const lightYellow = '#f8eda1';
const yellow = '#F7E886'; // TriMet Yellow
const darkYellow = '#D6C974';
const darkerYellow = '#BFAF5E';
const darkestYellow = '#999051';

const lightestOrange = '#f0a693';
const lighterOrange = '#e58168';
const lightOrange = '#d85a3b';
const orange = '#d1441e'; // TriMet Orange
const darkOrange = '#b53a19';
const darkerOrange = '#81260e';
const darkestOrange = '#501405';

const cyanDark = '#008ab0';
const transparent = 'transparent';

/**
 * A few values that are used in multiple themes
 * and or have specific use cases.
 */

const boxShadow = '0 1px 4px rgba(0,0,0,.55)';
const boxShadowLight = '0 1px 2px rgba(0,0,0,.35)';
const imageOverlayFrom = `rgba(8, 76, 141, 0.65)`;
const imageOverlayTo = `rgba(8, 76, 141, 0.65)`;
const inputRadius = '5px';
const inputShadow = '#0000001A';
const contentLegacyBg = '#0a559e';

/**
 * Some of the focus indicator uses are outside of the ThemeContext
 * Currently all themes have same focus styles, so exporting this directly for now
 */
export const FOCUS_INDICATOR = {
  inner: white,
  outer: black,
};

/**
 * The BASE_THEME should have constants that are shared between the light and dark themes.
 */

const BASE_THEME = {
  // Context Menu
  contextMenuButtonColor: gray,
  contextMenuButtonHoverColor: darkerGray,

  // Opacity helpers
  opacity_50: '0.5',
  opacity_70: '0.7',
  opacity_25: '0.25',

  white_15: 'rgba(255, 255, 255, 0.15)',
  white_25: 'rgba(255, 255, 255, 0.25)',
  white_50: 'rgba(255, 255, 255, 0.5)',

  // Buttons
  buttonRadiusSmall: '3px',
  buttonRadius: '5px',
  buttonRadiusLarge: '8px',
  buttonRounded: '10em',

  // Inputs
  inputDropDownRadius: `0 0 ${inputRadius} ${inputRadius}`,
  inputOpenRadius: `${inputRadius} ${inputRadius} 0 0`,
  inputDropDownShadow: `0 9px 15px ${inputShadow}`,
  inputShadow: `0 0 5px ${inputShadow}`,
  inputRadius,

  // LEGACY COLORS

  navBackgroundHover: '#00386E',
  selectedLanguageDropdownColor: '#DEEBFF',
  focusedLanguageDropdownColor: '#F1F7FF',
  stopHighlightColor: '#5cc3ff',
  stopBladeIconHighlight: '#5bc4fc',
  activeOmniboxULLIColor: 'rgb(51, 122, 183)',
  newOmniboxULLIColor: '#dedede',
  trimetOrange: '#d14727',
  trimetHighlight: '#F7E886',
  originColor: '#6cb33f',
  selectedStopIconColor: '#6DB33F',
  destinationColor: '#e53333',
  activeButtonColor: '#6DB33F',
  activeButtonHover: '#609E38',
  activeButtonHover2: '#e0e0e0',
  activeButtonFocus: '#609E38',
  currentPositionColor: '#62bd19',
  clearCurrentPositionColor: '#969696',
  selectedStopColor: '#efecbe',
  activeOmniboxSpanColor: '#084c8d',
  hoverOpacity: 0.6,
  readOnlyOpacity: 0.7,
  inactiveOpacity: 0.6,
  initialButtonOpacity: 0.65,
  realtimeNotificationColor: '#007494',
  tabActiveBorder: '#6cb33e',
  tabBackgroundColor: '#084c8d',
  tabColor: 'rgba(255,255,255,.6)',
  tabActiveColor: 'rgba(255,255,255,1)',
  routeDetailBadgeBorder: '#fff',
  routeStopFillColor: '#fff',
  routeStopStrokeColor: '#000',
  warningBackground: '#000000de',
  warningButtonHoverBackground: '#ffffff33',
  warningFontColor: '#fff',
  warningFontColorEmphasis: '#e7b008',
  textShadow: '0 1px 3px rgba(0,0,0,.25)',
  textShadowHover: '0 1px 4px rgba(0,0,0,.55)',
  closeShadow: 'drop-shadow(0 0 2px rgb(0 0 0 / 0.4))',
  closeShadowHover: 'drop-shadow(0 0 4px rgb(0 0 0 / 0.6))',
  buttonShadow: '0 1px 8px rgba(0,0,0,0.2)',
  buttonShadowHover: '0 0 5px rgba(0,0,0,0.2)',
  boxShadowPanel: '0 2px 10px rgba(0,0,0,0.4)',
  boxShadowInput: '0 2px 10px rgba(0,0,0,0.4)',
  boxShadowInputHover: '0 2px 10px rgba(0,0,0,0.6)',
  boxShadowButton: '0 0 5px rgba(0, 0, 0, 0.65)',
  boxShadowButtonHover: '0 0 5px rgba(0,0,0,0.85)',
  boxShadowCardHover: '0 0 5px rgba(0,0,0,0.3)',
  boxShadowTransition: 'box-shadow 0.125s ease-in-out',
  elementsTransition: 'all 0.25s ease',
  locationMarkerColor: '#3b7fc1',
  warnColor: '#ff8800',
  dangerColor: '#b50000',
  dangerColorHover: '#ba3d1b',
  baseAlertBackgroundColor: '#b03823',
  baseAlertHoverColor: '#c53f27',
  baseAlertBorderColor: '#a13320',
  // alerts > normal
  normalAlertIconFill: '#b03823',
  normalAlertBackgroundColor: '#FFFFCC',
  normalAlertHoverColor: '#575757',
  normalAlertBorderColor: '#c4c49d',
  normalAlertXColor: '#cecba2',
  normalViewText: '#000000A6',
  normalViewTextHover: '#ffffff',
  // alerts > priority
  priorityAlertBackgroundColor: '#b03823',
  priorityAlertHoverColor: '#7c2719',
  priorityAlertBorderColor: '#912e1d',
  priorityAlertXColor: '#912e1d',
  priorityViewTextHover: '#ffffff',
  // alerts > snow
  snowAlertBackgroundColor: '#4d84db',
  snowAlertHoverColor: '#376da3',
  snowAlertBorderColor: '#2468a8',
  snowAlertXColor: '#2468a8',
  // end alerts
  checkMarkColor: '#00a83e',
  notificationColor: '#d59f01',
  focusVisible: '#6cb33e',
  menuFooterBg: '#000',
  tripTopSkeleton: '#e9e9e9',
  tripStepSkeleton: '#f4f4f4',
  tripTopSkeletonTop: '#d4d4d4',
  stopTopSkeletonMain: '#003d78',
  stopTopSkeletonHighlight: '#084c8d',
  blue_15: 'rgba(8, 76, 141, 0.15)',
  blue_25: 'rgba(8, 76, 141, 0.25)',
  blue_50: 'rgba(8, 76, 141, 0.5)',
  white: '#fff',
  infoColor: '#939393',
};

/**
 * LIGHT THEME
 */

export const LIGHT_THEME = {
  ...BASE_THEME,
  name: 'Light',

  // Buttons

  // -> Primary Button
  primaryButtonBg: darkGreen,
  primaryButtonBgHover: darkerGreen,
  primaryButtonBorder: darkGreen,
  primaryButtonBorderHover: darkerGreen,
  primaryButtonText: white,
  // -> Outline Button
  outlineButtonBg: 'transparent',
  outlineButtonBgHover: darkerGray,
  outlineButtonBorder: charcoal,
  outlineButtonBorderHover: charcoal,
  outlineButtonText: charcoal,
  outlineButtonTextHover: white,
  // -> Outline Reverse Button
  outlineReverseButtonBg: 'transparent',
  outlineReverseButtonBgHover: darkerGray,
  outlineReverseButtonBorder: charcoal,
  outlineReverseButtonBorderHover: charcoal,
  outlineReverseButtonText: charcoal,
  outlineReverseButtonTextHover: white,

  // Menu

  menuBg: blue,
  menuWrapperBg: charcoal,
  menuPrimarySecondaryText: white,
  menuSecondaryLabels: lightestBlue,
  menuIconFill: white,
  menuAnimatedUnderline: green,
  menuFooterText: gray,
  menuFooterTextHover: white,
  menuFooterBullet: white,
  menuPromoChevronRight: orange,
  menuPromoText: white,
  menuMobileCloseIcon: white,
  menuPromoOverlay: `linear-gradient(${imageOverlayFrom} 0%, ${imageOverlayTo} 100%)`,
  menuNavBg: blue,

  // DetailNavHeader (Back, MinMax, Close buttons)
  navHeaderBg: `linear-gradient(360deg, ${lighterGray} 0%, ${white} 100%);`,
  navHeaderTitle: charcoal,
  navIconFill: charcoal,
  navIconBgHover: lightGray,

  // SidePanel content
  contentBg: white,
  contentBgWithCards: lightestGray,
  contentText: charcoal,

  // Card
  cardBg: white,
  cardBorder: lighterGray,
  cardClosedShadow: 'none',
  cardOpenShadow: boxShadowLight,
  cardShadowHover: boxShadow,
  cardExternalLink: charcoal,
  cardExternalLinkBgHover: lighterGray,
  cardText: charcoal,

  // PopoverMenuList
  popoverMLEllipsesFill: white,
  popoverMLBg: white,
  popoverMLBorder: lighterGray,
  popoverMLItemHover: lighterGray,
  popoverMLText: charcoal,

  // Vehicle tracking
  vehicleTrackerHighlightColor: cyanDark,
  vehicleTrackerLowlightColor: lightestBlue,
  vehicleTrackerHighlightBackingColor: gray,
  vehicleTrackerBaseColor: transparent,

  // Inputs: Drop downs, all things around and within...
  dropDownContentBg: white,
  dropDownInputBg: white,
  dropDownIndicator: charcoal,
  dropDownText: charcoal,
  dropDownTextSelected: white,
  dropDownTextDim: gray,
  dropDownLabelText: charcoal,
  dropDownBorder: lightGray,
  dropDownInputRadius: BASE_THEME.inputOpenRadius,
  dropDownContentRadius: BASE_THEME.inputDropDownRadius,
  dropDownInputShadow: BASE_THEME.inputDropDownShadow,
  dropDownContentShadow: BASE_THEME.inputDropDownShadow,
  dropDownOptionSelectedCheck: lighterGreen,
  dropDownOptionSelected: darkGreen,
  dropDownOptionSelectedHover: lightestGreen,
  dropDownTimeInputFocus: lighterGreen,
  dropDownTimeDateTabFocus: lightGreen,
  dropDownEditBg: lighterGray,
  dropDownEditBgHover: lightestGray,
  dropDownSaveCloseBgHover: lighterGray,

  // TODO: (PRJ77-727) Update these with actual colors for the given theme
  // Checkbox
  checkboxBg: white,
  checkboxBorder: lightGray,
  checkboxBorderHover: gray,
  checkboxCheck: white,
  checkboxCheckHover: white,
  checkboxCheckDisabled: white,
  checkboxCheckedBg: darkGreen,
  checkboxLabelText: white,

  // The code above is the new light mode, organized by component
  // The code below is the old light mode, which should be replaced by the new light mode

  mainColor: '#084c8d',
  secondaryColor: '#385979',
  tertiaryColor: '#e8eef4',
  backgroundColor: '#f5f5f5',
  secondaryBackgroundColor: '#ffffff',
  primaryFontColor: '#222',
  primaryFontColorReverse: '#fff',
  secondaryFontColor: '#575757',
  popperFontColor: '#707070',
  hoverColor: '#f5f5f5',
  hoverColor2: '#d8ecff',
  activeColor: '#dedede',
  hoverColorInverted: '#4573a1',
  activeColorInverted: '#28598a',
  focusColorInverted: '#5c8ab9',
  badgeBorderColor: 'transparent',
  successColor: '#008ab0',
  actionColor: '#4777a6',
  borderColor: '#efefef',
  buttonColor1: '#0080A3',
  buttonColor1Hover: '#007191',
  buttonColor2: '#efefef',
  buttonText1: '#fff',
  buttonText2: '#555',
  inverseSecondaryButtonBgColor: '#dedede',
  inverseSecondaryButtonBgColorHover: '#cecece',
  inverseBackgroundColor1: '#084c8d',
  inverseBackgroundColor2: '#0a559e',
  inverseBackgroundColor3: '#003d78',
  inverseBackgroundColor4: '#002B54',
  inverseFontColor1: '#ecf6ff',
  inverseFontColor2: '#A5C0D5',
  inverseFontColor3: '#084c8d',
  inverseButtonColor1: '#3F70A2',
  inverseButtonColor2: '#6896c4',
  inverseButtonColor3: '#86abd0',
  inactiveColor: '#808080',
  inputBorderColor: '#cecece',
  inputBorderColorFocus: '#000',
  inputBorderColorReverse: '#3F70A2',
  inputBorderColorFocusReverse: '#fff',
  inputBgColorReverse: '#084c8d',
  inputBgColorReverseFocus: '#003d78',
  stroke: '#e8eef4',
  cardBackgroundColor: '#0D5EAC',
  primaryButtonBgColor: '#6cb33e',
  primaryButtonHoverBgColor: '#62a038',
  primaryButtonTextShadow: '0 1px 2px rgba(0,0,0,.65)',
  tripModeBgColor: '#0d5eac',
  dueTextColor: '#4E802D',
  skeletonBaseColor: '#d5d5d5',
};

/**
 * DARK THEME
 */

export const DARK_THEME = {
  ...BASE_THEME,
  name: 'Dark',

  // Buttons

  // -> Primary Button
  primaryButtonBg: darkGreen,
  primaryButtonBgHover: darkerGreen,
  primaryButtonBorder: darkGreen,
  primaryButtonBorderHover: darkerGreen,
  primaryButtonText: white,
  // -> Outline Button
  outlineButtonBg: 'transparent',
  outlineButtonBgHover: black,
  outlineButtonBorder: white,
  outlineButtonBorderHover: white,
  outlineButtonText: white,
  outlineButtonTextHover: white,
  // -> Outline Reverse Button
  outlineReverseButtonBg: 'transparent',
  outlineReverseButtonBgHover: black,
  outlineReverseButtonBorder: white,
  outlineReverseButtonBorderHover: white,
  outlineReverseButtonText: white,
  outlineReverseButtonTextHover: white,

  // Menu
  menuBg: lightestGray,
  menuWrapperBg: charcoal,
  menuPrimarySecondaryText: charcoal,
  menuSecondaryLabels: darkGray,
  menuIconFill: white,
  menuAnimatedUnderline: green,
  menuFooterText: gray,
  menuFooterTextHover: white,
  menuFooterBullet: white,
  menuPromoChevronRight: orange,
  menuMobileCloseIcon: charcoal,
  menuPromoOverlay: `linear-gradient(${imageOverlayFrom} 0%, ${imageOverlayTo} 100%)`,
  menuNavBg: blue,

  // DetailNavHeader (Back, MinMax, Close buttons)
  navHeaderBg: `linear-gradient(180deg, ${darkestDeepBlue} 0%, ${deepBlue} 100%);`,
  navHeaderTitle: white,
  navIconFill: white,
  navIconBgHover: lightDeepBlue,

  // SidePanel content
  contentBg: darkestDeepBlue,
  contentBgWithCards: darkerDeepBlue,
  contentText: white,

  // Card
  cardBg: darkestDeepBlue,
  cardBorder: darkGray,
  cardClosedShadow: 'none',
  cardOpenShadow: boxShadowLight,
  cardShadowHover: boxShadow,
  cardExternalLink: white,
  cardExternalLinkBgHover: lightDeepBlue,
  cardText: white,

  // PopoverMenuList
  popoverMLEllipsesFill: white,
  popoverMLBg: white,
  popoverMLBorder: lighterGray,
  popoverMLItemHover: lighterGray,
  popoverMLText: charcoal,

  // Vehicle tracking
  vehicleTrackerHighlightColor: cyanDark,
  vehicleTrackerLowlightColor: lightestBlue,
  vehicleTrackerHighlightBackingColor: gray,
  vehicleTrackerBaseColor: transparent,

  // Inputs: Drop downs, all things around and within...
  dropDownContentBg: lightDeepBlue,
  dropDownInputBg: lightDeepBlue,
  dropDownIndicator: white,
  dropDownText: white,
  dropDownTextSelected: charcoal,
  dropDownTextDim: lightGray,
  dropDownLabelText: white,
  dropDownBorder: darkGray,
  dropDownInputRadius: BASE_THEME.inputOpenRadius,
  dropDownContentRadius: BASE_THEME.inputDropDownRadius,
  dropDownInputShadow: BASE_THEME.inputDropDownShadow,
  dropDownContentShadow: BASE_THEME.inputDropDownShadow,
  dropDownOptionSelectedCheck: charcoal,
  dropDownOptionSelected: lightGreen,
  dropDownOptionSelectedHover: darkerGreen,
  dropDownTimeInputFocus: lighterGreen,
  dropDownTimeDateTabFocus: lighterGreen,
  dropDownEditBg: deepBlue,
  dropDownEditBgHover: darkerDeepBlue,
  dropDownSaveCloseBgHover: darkDeepBlue,

  // TODO: (PRJ77-727) Update these with actual colors for the given theme
  // Checkbox
  checkboxBg: white,
  checkboxBorder: lightGray,
  checkboxBorderHover: gray,
  checkboxCheck: white,
  checkboxCheckHover: white,
  checkboxCheckDisabled: white,
  checkboxCheckedBg: darkGreen,
  checkboxLabelText: white,

  // The code above is the new dark mode, organized by component
  // The code below is the old dark mode, which should be replaced by the new dark mode

  mainColor: '#A6D4FF',
  secondaryColor: '#E0F0FF',
  tertiaryColor: '#595959',
  backgroundColor: '#1f1f1f',
  secondaryBackgroundColor: '#2b2b2b',
  primaryFontColor: '#fff',
  primaryFontColorReverse: '#1f1f1f',
  secondaryFontColor: '#ccc',
  popperFontColor: '#b3b3b3',
  hoverColor: '#333',
  activeColor: '#222',
  hoverColorInverted: '#efefef',
  activeColorInverted: '#dedede',
  focusColorInverted: '#dedede',
  badgeBorderColor: 'transparent',
  successColor: '#007e33',
  actionColor: '#5c97d1',
  borderColor: '#2b2b2b',
  buttonColor1: '#008ab0',
  buttonColor1Hover: '#007101',
  buttonColor2: '#efefef',
  buttonText1: 'white',
  buttonText2: '#555',
  softToastShadowColor: 'rgba(0, 0, 0, 0.5)',
  inverseBackgroundColor1: '#084c8d',
  inverseBackgroundColor2: '#0a559e',
  inverseBackgroundColor3: '#003d78',
  inverseBackgroundColor4: '#002345',
  inverseFontColor1: '#ecf6ff',
  inverseFontColor2: '#A5C0D5',
  inverseButtonColor1: '#3F70A2',
  inverseButtonColor2: '#6896c4',
  inverseButtonColor3: '#86abd0',
  inactiveColor: '#808080',
  inputBgColor: '#084c8d',
  inputBgColorFocus: '#003d78',
  inputBgColorReverse: '#f5f5f5',
  inputBgColorReverseFocus: '#e1e1e1',
  stroke: '#595959',
  cardBackgroundColor: '#0D5EAC',
  tripModeBgColor: '#0d5eac',
  dueTextColor: '#4E802D',
  skeletonBaseColor: '#d5d5d5',
};

/**
 * The LEGACY THEME will be retro fitted to match the old light theme. Then we will decommision when theme migration is complete.
 */

export const LEGACY_THEME = {
  ...BASE_THEME,
  name: 'Legacy',

  // Buttons

  // -> Primary Button
  primaryButtonBg: darkGreen,
  primaryButtonBgHover: darkerGreen,
  primaryButtonBorder: darkGreen,
  primaryButtonBorderHover: darkerGreen,
  primaryButtonText: white,
  // -> Outline Button
  outlineButtonBg: 'transparent',
  outlineButtonBgHover: BASE_THEME.white_15,
  outlineButtonBorder: white,
  outlineButtonBorderHover: white,
  outlineButtonText: white,
  outlineButtonTextHover: white,
  // -> Outline Reverse Button
  outlineReverseButtonBg: 'transparent',
  outlineReverseButtonBgHover: charcoal,
  outlineReverseButtonBorder: charcoal,
  outlineReverseButtonBorderHover: charcoal,
  outlineReverseButtonText: charcoal,
  outlineReverseButtonTextHover: white,

  // Menu
  menuBg: blue,
  menuWrapperBg: charcoal,
  menuPrimarySecondaryText: white,
  menuSecondaryLabels: lightestBlue,
  menuIconFill: white,
  menuAnimatedUnderline: green,
  menuFooterText: gray,
  menuFooterTextHover: white,
  menuFooterBullet: white,
  menuPromoChevronRight: orange,
  menuPromoText: white,
  menuMobileCloseIcon: white,
  menuPromoOverlay: `linear-gradient(${imageOverlayFrom} 0%, ${imageOverlayTo} 100%)`,
  menuNavBg: blue,

  // DetailNavHeader (Back, MinMax, Close buttons)
  navHeaderBg: blue,
  navHeaderTitle: white,
  navIconFill: white,
  navIconBgHover: darkerBlue,

  // SidePanel content
  contentBg: lightBlue,
  contentBgWithCards: contentLegacyBg,
  contentText: charcoal,

  // Card
  cardBg: white,
  cardBorder: lighterGray,
  cardClosedShadow: 'none',
  cardOpenShadow: boxShadowLight,
  cardShadowHover: boxShadow,
  cardExternalLink: charcoal,
  cardExternalLinkBgHover: lighterGray,
  cardText: charcoal,

  // PopoverMenuList
  popoverMLEllipsesFill: white,
  popoverMLBg: white,
  popoverMLBorder: lighterGray,
  popoverMLItemHover: lighterGray,
  popoverMLText: charcoal,

  // Vehicle tracking
  vehicleTrackerHighlightColor: cyanDark,
  vehicleTrackerLowlightColor: lightestBlue,
  vehicleTrackerHighlightBackingColor: gray,
  vehicleTrackerBaseColor: transparent,

  // Inputs: Drop downs, all things around and within...
  dropDownContentBg: white,
  dropDownInputBg: white,
  dropDownIndicator: charcoal,
  dropDownText: charcoal,
  dropDownTextSelected: white,
  dropDownTextDim: gray,
  dropDownLabelText: white,
  dropDownBorder: lightGray,
  dropDownInputRadius: BASE_THEME.inputOpenRadius,
  dropDownContentRadius: BASE_THEME.inputDropDownRadius,
  dropDownInputShadow: BASE_THEME.inputDropDownShadow,
  dropDownContentShadow: BASE_THEME.inputDropDownShadow,
  dropDownOptionSelectedCheck: lightestGreen,
  dropDownOptionSelected: darkGreen,
  dropDownOptionSelectedHover: lightGray,
  dropDownTimeInputFocus: lightGray,
  dropDownTimeDateTabFocus: darkGreen,
  dropDownEditBg: lighterGray,
  dropDownEditBgHover: lightestGray,
  dropDownSaveCloseBgHover: lighterGray,

  // TODO: (PRJ77-727) Update these with actual colors for the given theme
  // Checkbox
  checkboxBg: white,
  checkboxBorder: lightGray,
  checkboxBorderHover: gray,
  checkboxCheck: white,
  checkboxCheckHover: white,
  checkboxCheckDisabled: white,
  checkboxCheckedBg: darkGreen,
  checkboxLabelText: white,

  // This is the legacy theme which was a copy of the light theme at some point.
  // This LEGACY_THEME should look like the old light theme, eventually this entire
  // theme will be retired.

  mainColor: '#084c8d',
  secondaryColor: '#385979',
  tertiaryColor: '#e8eef4',
  backgroundColor: '#f5f5f5',
  secondaryBackgroundColor: '#ffffff',
  primaryFontColor: '#222',
  primaryFontColorReverse: '#fff',
  secondaryFontColor: '#575757',
  popperFontColor: '#707070',
  hoverColor: '#f5f5f5',
  hoverColor2: '#d8ecff',
  activeColor: '#dedede',
  hoverColorInverted: '#4573a1',
  activeColorInverted: '#28598a',
  focusColorInverted: '#5c8ab9',
  badgeBorderColor: 'transparent',
  successColor: '#008ab0',
  actionColor: '#4777a6',
  borderColor: '#efefef',
  buttonColor1: '#0080A3',
  buttonColor1Hover: '#007191',
  buttonColor2: '#efefef',
  buttonText1: '#fff',
  buttonText2: '#555',
  inverseSecondaryButtonBgColor: '#dedede',
  inverseSecondaryButtonBgColorHover: '#cecece',
  inverseBackgroundColor1: '#084c8d',
  inverseBackgroundColor2: '#0a559e',
  inverseBackgroundColor3: '#003d78',
  inverseBackgroundColor4: '#002B54',
  inverseFontColor1: '#ecf6ff',
  inverseFontColor2: '#A5C0D5',
  inverseFontColor3: '#084c8d',
  inverseButtonColor1: '#3F70A2',
  inverseButtonColor2: '#6896c4',
  inverseButtonColor3: '#86abd0',
  inactiveColor: '#808080',
  inputBorderColor: '#cecece',
  inputBorderColorFocus: '#000',
  inputBorderColorReverse: '#3F70A2',
  inputBorderColorFocusReverse: '#fff',
  inputBgColorReverse: '#084c8d',
  inputBgColorReverseFocus: '#003d78',
  stroke: '#e8eef4',
  cardBackgroundColor: '#0D5EAC',
  primaryButtonBgColor: '#6cb33e',
  primaryButtonHoverBgColor: '#62a038',
  primaryButtonTextShadow: '0 1px 2px rgba(0,0,0,.65)',
  tripModeBgColor: '#0d5eac',
  dueTextColor: '#4E802D',
  skeletonBaseColor: '#d5d5d5',
};
