import { Link } from 'gatsby';
import styled from 'styled-components';

export const ClearGatsbyLink = styled(Link)`
  /* Allow hover only on devices with mouse hover */
  @media (any-hover: hover) {
    &:hover {
      background-color: ${props => props.theme.hoverColor};
    }
  }
`;

export default ClearGatsbyLink;
